import { useQuery } from "@tanstack/react-query";
import { CatalogKey } from "../../ultils/keys";
import { getCourseCatalog } from "../../actions/CatalogAction";
import { useEffect } from "react";
const useGetCourseCatalog = (
  faculty: number,
  session: string,
  year: number,
  onFocus?: boolean
) => {
  const { data, refetch, isLoading, isRefetching, isError } = useQuery(
    [CatalogKey, [faculty, year, session]],
    () => getCourseCatalog(faculty, session, year || 1),
    {
      refetchOnWindowFocus: onFocus || false,
    }
  );

  useEffect(() => {
    refetch();
  }, [year, session, faculty, refetch]);

  return { data, refetch, isLoading, isRefetching, isError };
};

export default useGetCourseCatalog;
