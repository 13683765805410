import axios from "axios";

// create grades
export const AddGrades = (
  students: number,
  course: number,
  faculty: number,
  level: number,
  data: any
) => {
  return axios
    .post(`/notes/${students}/${course}/${faculty}/${level}`, {
      semestre: data.semestre,
      notes: parseInt(data.notes),
      remarques: data.remarques,
      annee_academique: data.annee_academique,
    })
    .then((res) => res.data);
};

export const EditGrades = (id: number, data: any) => {
  return axios.patch(`/notes/${id}`, data).then((res) => res.data);
};

// grades list
// create grades
export const GradesList = (
  students: number,
  semetre: number,
  year: number,
  level: number,
  course: number,
  faculty: number
) => {
  return axios
    .get(`/notes/${students}/${semetre}/${year}/${level}/${course}/${faculty}`)
    .then((res) => res.data);
};

export const GradesListForStudent = (
  students: number,
  semetre: string,
  year: number,
  level: number,
  faculty: number
) => {
  return axios
    .get(`/notes/${students}/${semetre}/${year}/${level}/${faculty}`)
    .then((res) => res.data);
};
