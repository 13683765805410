import * as yup from "yup";

const CreateGradesShema = yup.object().shape({
  note: yup
    .number()
    .typeError("vous devez spécifier un nombre")
    .min(0, "Valeur minimale 0")
    .max(100, "Valeur maximale 100")
    .required("Ce champ est obligatoire !"),
});

export default CreateGradesShema;
