import React from "react";
import useGetCourseCatalog from "../../hooks/catalogs/useGetCourseCatalog";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CourseListButtom } from "./CourseListButtom";
export const CourseListForGrades = () => {
  // useLoacation
  const location = useLocation();
  // query string data
  const parsed: any = queryString.parse(location.search);
  const { fac }: any = useParams();

  const { data, isLoading, isRefetching, isError } = useGetCourseCatalog(
    fac,
    parsed.sem,
    parsed.level
  );

  return <>{data && <CourseListButtom courses={data} />}</>;
};
