import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GradesKey } from "../../ultils/keys";
import { Toast } from "../../components/layout/Toast";
import swal from "sweetalert";
import { AddGrades } from "../../actions/GradesActions";

const useCreateGrades = (
  students: number,
  course: number,
  faculty: number,
  level: number
) => {
  // initialize query client
  const queryClient = useQueryClient();

  const { isLoading, mutate, error, isError, isSuccess } = useMutation(
    async (data: any) => {
      return AddGrades(students, course, faculty, level, data);
    },
    {
      onMutate: async (result) => {},
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err: any, newData, context: any) => {
        //queryClient.setQueryData([GradesKey], context.previousTodos);
        swal("Oops", err.response.data.message, "error");
      },
      onSuccess: (result, variables, context: any) => {
        swal("Félicitation!", "Cours enregistré avec succès!", "success");
        //Toast("success", "Cours enregistré avec succès");
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries([GradesKey]);
      },
    }
  );

  return { isLoading, mutate, error, isError, isSuccess };
};

export default useCreateGrades;
