import { Divider, Table } from "antd";
import { StudentSearchBar } from "./StudentSearchBar";
import { useAppSelector } from "../../redux/hooks/hooks";
import { Container } from "../Ui/Container";
import { CreateGradesForm } from "./../grades/CreateGradesForm";
import { CourseListForGrades } from "./../grades/CourseListForGrades";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";

const StudentsListForGrades = ({ data }: any) => {
  // use Location
  const location = useLocation();
  // query string data
  const parsed: any = queryString.parse(location.search);
  // students state
  const student = useAppSelector((state) => state.student);
  const [students_, setStudents_] = useState([]);
  // query stirng variable

  useEffect(() => {
    const filteredStudents = data.filter(
      (student: any) =>
        student.annee === parsed.year && student.niveau === parsed.level
    );

    setStudents_(filteredStudents);
  }, [data, parsed.level, parsed.year]);

  const columns: any = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "Nom",
      render: (_: any, items: any) => (
        <>
          <Link to={`/dashbord/profile-students/${items.uuid}`}>
            <b>{items.nom + " " + items.prenom}</b>
          </Link>
        </>
      ),
    },

    {
      key: "id",
      render: (_: any, items: any) => (
        <>
          <CreateGradesForm students={items} />
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <div className='row'>
          <StudentSearchBar dataForFiltered={students_} />
          <div className='col-md-6 col-xs-12 mb-2 '>
            <CourseListForGrades />
          </div>
        </div>

        <Divider />
        <Table
          columns={columns}
          dataSource={student.filtered !== null ? student.filtered : students_}
          rowKey='id'
          showHeader={false}
        />
      </Container>
    </>
  );
};

export default StudentsListForGrades;
