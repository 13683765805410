import React from "react";
import useShowCourse from "../../../hooks/courses/useShowCourse";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { IsRefetching } from "./../../layout/IsRefetching";

export const NameCourseForGrades = () => {
  const location = useLocation();
  const queryStringValue: any = queryString.parse(location.search);

  const { course, isLoading, isError } = useShowCourse(queryStringValue.course);

  return (
    <>
      {queryStringValue.sem &&
        queryStringValue.year &&
        queryStringValue.grades && (
          <IsRefetching loading={queryStringValue.course ? isLoading : false}>
            <div
              className=' mb-0'
              style={{
                padding: "1px",
                backgroundColor: `${course ? "#7d56c2" : "#d0311e"}`,
              }}
            >
              <h5 className='text-primary text-white'>
                &nbsp;
                {course
                  ? course.nom_cours
                  : isLoading && queryStringValue.course
                  ? "En train de charger..."
                  : isError
                  ? "Cours introuvable"
                  : "Choisir un cours"}
              </h5>
            </div>
          </IsRefetching>
        )}
    </>
    // <IsRefetching loading={queryStringValue.course ? isLoading : false}>
    //   <div
    //     className='card d-block mb-0 p-1'
    //     style={{ backgroundColor: `${course ? "#7d56c2" : "#d0311e"}` }}
    //   >
    //     <h4 className='text-primary bold text-white'>
    //       <i className='fa fa-file'></i> &nbsp;
    //       {course ? course.nom_cours : "Choisir un cours"}
    //     </h4>
    //   </div>
    // </IsRefetching>
  );
};
