import { AppTitle } from "./../../components/partials/AppTitle";
import StudentsList from "./../../components/students/StudentsList";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { StudentKey } from "./../../ultils/keys";
import { DataLoading } from "./../../components/layout/DataLoading";
import { NotFoundTemplate } from "./../../components/layout/NotFoundTemplate";
import { Tag } from "antd";
import { GetStudentsByFaculty } from "../../actions/StudentAction";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import StudentsListForGrades from "./../../components/students/StudentsListForGrades";
import { NameCourseForGrades } from "./../../components/grades/ui/NameCourseForGrades";
import { useShowFaculty } from "../../hooks/faculties/useShowFaculty";
import { BannerGrades } from "./../../components/grades/BannerGrades";

export const SudentsByFaculties = () => {
  // use navigation
  const location = useLocation();
  const { fac } = useParams();
  const queryStringValue = queryString.parse(location.search);

  // select the faculty
  const { isLoading, faculty: data, isError, error } = useShowFaculty(fac);

  // students by fculy
  const { data: students, isLoading: loading } = useQuery(
    [StudentKey, parseInt(fac)],
    () => GetStudentsByFaculty(fac)
  );

  const dataFilter = () => {
    if (students) {
      return students.filter((students) => {
        console.log(students);
        return (
          students.niveau === queryStringValue.level &&
          students.annee === queryStringValue.year
        );
      });
    }
  };

  // course name
  const err = error;

  return (
    <>
      <AppTitle title={"Liste des étudiants par faculte"} img='employees' />
      {isLoading ? (
        <DataLoading />
      ) : isError ? (
        <NotFoundTemplate message={err.response.data.message} />
      ) : (
        <>
          <div className='card d-block mb-1'>
            <div className='card-body'>
              <Tag color='#cd201f'>
                <b>{data.degree.toUpperCase()}</b>
              </Tag>

              {/* <div className='clearfix' /> */}
              <h4 className='text-primary bold'>
                <i className='fa fa-graduation-cap'></i> {data.nom_faculte}
              </h4>
              <p>{data.description}</p>
            </div>
            {queryStringValue && queryStringValue.level && <BannerGrades />}
          </div>

          <NameCourseForGrades />
        </>
      )}

      {!loading ? (
        <>
          {(queryStringValue.sem &&
            queryStringValue.year &&
            queryStringValue.grades) ||
          queryStringValue.course ? (
            <StudentsListForGrades data={dataFilter()} />
          ) : (
            <StudentsList data={dataFilter()} />
          )}
        </>
      ) : (
        <DataLoading title='Etudiants est en train de charger....' />
      )}
    </>
  );
};
