import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const BannerGrades = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  return (
    <table className='table table-bordered mb-0' style={{ marginTop: "-20px" }}>
      <thead>
        <tr>
          <th>Niveau</th>
          <th>Semetre</th>
          <th>Annee Academique</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {" "}
            {parsed && parsed.level === "1"
              ? parsed.level + "ère "
              : parsed && parsed.level !== "1"
              ? parsed.level + "ème "
              : 1 + "ère "}
            année
          </td>
          <td>{parsed.sem}</td>
          <td>{parsed.year}</td>
        </tr>
      </tbody>
    </table>
  );
};
