import { FacultyKey } from "../../ultils/keys";
import { useQuery } from "@tanstack/react-query";
import { GetFaculty } from "../../actions/FacultyActions";

export const useShowFaculty = (id: string) => {
  const {
    data: faculty,
    isLoading,
    isRefetching,
    isError,
    error,
  } = useQuery([FacultyKey, parseInt(id)], () => GetFaculty(id));

  return { isLoading, faculty, isRefetching, isError, error };
};
