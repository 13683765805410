import React from "react";
import { ModalTemplate } from "../Ui/ModalTemplate";
import { EditGradesForm } from "./EditGradesForm";
import useEditGrades from "../../hooks/grades/useEditGrades";
import { RiEditBoxFill } from "react-icons/ri";

export const EditGrades = ({ students, grades }: any) => {
  const { isLoading, mutate, isSuccess } = useEditGrades(grades.id);

  const onSubmit = (data: any) => {
    mutate({
      notes: data.note_edit,
      isreprise: data.isReprise,
    });
  };

  return (
    <>
      <ModalTemplate
        title={
          <>
            <h4>Modifier la note - {grades.courses.nom_cours}</h4>
          </>
        }
        isSuccess={isSuccess}
        button={
          <button
            style={{ position: "absolute", right: 16, top: 16 }}
            className={`input-group-text btn btn-secondary waves-effect`}
            type='button'
          >
            <b>Modifier</b>
          </button>
        }
      >
        <EditGradesForm
          onSubmit={onSubmit}
          loading={isLoading}
          grades={grades}
          students={students}
        />
      </ModalTemplate>
    </>
  );
};
