import { useQuery } from "@tanstack/react-query";
import { GradesKey } from "../../ultils/keys";
import { GradesList } from "../../actions/GradesActions";
import { useEffect } from "react";
const useGradesList = (
  students: number,
  semetre: number,
  year: number,
  level: number,
  course: number,
  faculty: number
) => {
  const {
    data: grades,
    isLoading,
    isRefetching,
    refetch,
    isError,
    error,
  } = useQuery(
    [GradesKey, [students, semetre, level]],
    () => GradesList(students, semetre, year, level, course, faculty),
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    refetch();
  }, [students, semetre, year, level, course, faculty, refetch]);

  return { grades, isLoading, isRefetching, refetch, isError, error };
};

export default useGradesList;
