import React, { useState } from "react";
import { Drawer } from "antd";
import LeftSideBar from "./LeftSideBar";
import { MenuAvatar } from "../Ui/MenuAvatar";

export const ResponsiveMenu = () => {
  const [open, setOpen] = useState(false);

  const drawer = () => {
    setOpen(!open);
  };

  //   const onClose = () => {
  //     setOpen(false);
  //   };

  const onclick = () => {
    setOpen(false);
  };
  return (
    <>
      <span
        onClick={drawer}
        style={{
          cursor: "pointer",
          fontSize: "20px",
          position: "fixed",
          top: "14px",
          zIndex: "1000",
          left: "10px",
          color: "white",
        }}
      >
        <MenuAvatar img={"menu"} size={40} />
      </span>

      {/* <Button type='primary' onClick={drawer}>
        Open
      </Button> */}
      <Drawer
        title='Menu de navigation'
        placement='left'
        onClose={drawer}
        open={open}
        width={264}
      >
        <span
          onClick={onclick}
          style={{ width: "100%", backgroundColor: "red" }}
        >
          <LeftSideBar display={true} />
        </span>
      </Drawer>
    </>
  );
};
