import { createSlice } from "@reduxjs/toolkit";
// Define a type for the slice state
interface MainState {
  isOpenModal: boolean;
}

// Define the initial state using that type
const initialState: MainState = {
  isOpenModal: false,
};

export const MainSlice = createSlice({
  name: "catalog",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    isOpenModal: (state, action) => {
      state.isOpenModal = action.payload;
    },
  },
});

export const { isOpenModal } = MainSlice.actions;
export default MainSlice.reducer;
