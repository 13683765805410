export const FacultyKey = "faculties";
export const StudentKey = "students";
export const UserKey = "users";
export const EmployeeKey = "employees";
export const CourseKey = "courses";
export const TeacherKey = "teachers";
export const CatalogKey = "catalogs";
export const RegislationKey = "regislations";
export const GradesKey = "grades";
export const YearKey = "year";
