import React, { useEffect, useState } from "react";
import {
  AiFillCheckCircle,
  AiFillCheckSquare,
  AiFillPlusSquare,
} from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CreateGradesShema from "../../shemaForms/grades/CreateGradesShema";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import useShowCourse from "../../hooks/courses/useShowCourse";
import { useShowFaculty } from "../../hooks/faculties/useShowFaculty";
import useCreateGrades from "../../hooks/grades/useCreateGrades";
import useGradesList from "../../hooks/grades/useGradesList";
import { EditGradesForm } from "./EditGradesForm";
import { EditGrades } from "./EditGrades";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { IsRefetching } from "./../layout/IsRefetching";
import { Alert } from "@mui/material";

export const CreateGradesForm = ({ students }: any) => {
  const location = useLocation();
  const { fac }: any = useParams();
  const queryStringValue: any = queryString.parse(location.search);

  // course select
  const { course, isSuccess } = useShowCourse(queryStringValue.course);
  // faculty select
  const { isLoading, faculty } = useShowFaculty(fac);

  const { grades, isLoading: loadingGrades }: any = useGradesList(
    students.studentId,
    queryStringValue.sem,
    queryStringValue.year,
    queryStringValue.level,
    queryStringValue.course,
    fac
  );

  // add grades
  const {
    isLoading: loading,
    mutate,
    error: errorGrade,
    isError: isErrorGrade,
    isSuccess: isSuccessGrade,
  } = useCreateGrades(
    students?.studentId,
    course?.id,
    faculty?.id,
    queryStringValue.level
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateGradesShema),
  });

  const onSubmit = (data: any) => {
    const values = {
      notes: data.note,
      remarques: "-",
      semestre: queryStringValue.sem,
      annee_academique: queryStringValue.year,
      niveau: queryStringValue.level,
    };
    mutate(values);
  };

  useEffect(() => {
    if (isSuccessGrade) {
      reset();
    }
  }, [isSuccessGrade, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='input-group'>
          {isErrorGrade || errorGrade ? (
            <Alert variant='filled' severity='error'>
              Une erreur s'est produite, réessayer
            </Alert>
          ) : (
            <>
              {!grades?.notes ? (
                <>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Saisir la note'
                    aria-label="Recipient's username"
                    {...register("note")}
                    disabled={!isSuccess && !isLoading}
                  />
                </>
              ) : (
                <>
                  &nbsp;
                  <input
                    className='form-control'
                    value={grades && grades.notes}
                    disabled
                    style={{
                      backgroundColor:
                        grades?.notes >= grades.faculte.note_de_passage
                          ? "green"
                          : "red",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  />
                </>
              )}
            </>
          )}
          {!grades?.notes && (
            <button
              className={`input-group-text btn btn-primary waves-effect`}
              type='submit'
              disabled={(!isSuccess && !isLoading) || loading}
            >
              <span
                className={`${
                  loading && "spinner-border"
                } spinner-border-sm me-1`}
                role='status'
                aria-hidden='true'
              />
              <b>Ajouter</b>
            </button>
          )}
        </div>

        <div className='errors-field'>{errors.note?.message?.toString()}</div>
      </form>
      {grades?.notes && (
        <>
          <EditGrades students={students} grades={grades && grades} />
        </>
      )}
    </>
  );
};
