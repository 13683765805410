import { Divider, Table } from "antd";
import { StudentSearchBar } from "./StudentSearchBar";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks/hooks";
import { Container } from "./../Ui/Container";
import { Button } from "./../Ui/Button";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const StudentsList = ({ data }: any) => {
  // students state
  const student = useAppSelector((state) => state.student);
  // query stirng variable

  const columns: any = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "Nom",
      render: (_: any, items: any) => items.person?.nom || items.nom,
    },

    {
      title: "Prenom",
      dataIndex: "prenom",
      key: "Prenom",
      render: (_: any, items: any) => items.person?.prenom || items.prenom,
    },

    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "Telephone",
      responsive: ["md"],
      render: (_: any, items: any) =>
        items.person?.telephone || items.telephone,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email",
      responsive: ["md"],
      render: (_: any, items: any) => items.person?.email || items.email,
    },
    {
      title: " Lieu de naissance",
      dataIndex: "lieu_naissance",
      key: "naissance",
      responsive: ["md"],
      render: (_: any, items: any) =>
        items.lieu_naissance || items.lieu_naissance,
    },

    {
      title: "	Date de naissance",
      dataIndex: "date_naissance",
      key: "naissance",
      responsive: ["md"],
      render: (_: any, items: any) =>
        moment(items.person?.date_naissance || items.date_naissance).format(
          "DD MMM YYYY"
        ),
    },
    {
      key: "id",
      render: (_: any, items: any) => (
        <>
          <Button
            link={`/dashbord/profile-students/${
              items.person?.uuid || items.uuid
            }`}
            className='btn btn-primary col-xs-12 bold p-1'
          >
            <FaUserCircle size={20} />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <div className='row'>
          <StudentSearchBar dataForFiltered={data} />
          <div className='col-md-6 col-xs-12 mb-2 '>
            <Button
              link='/dashbord/add-students'
              className='btn btn-primary col-xs-12 btn-blok bold'
            >
              <BsPersonFillAdd size={23} /> Ajouter etudiant
            </Button>
          </div>
        </div>

        <Divider />

        <Table
          columns={columns}
          dataSource={student.filtered !== null ? student.filtered : data}
          rowKey='id'
        />
      </Container>
    </>
  );
};

export default StudentsList;
