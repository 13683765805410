import * as yup from "yup";

const EditGradesShema = yup.object().shape({
  note_edit: yup
    .number()
    .typeError("vous devez spécifier un nombre")
    .min(0, "Valeur minimale 0")
    .max(100, "Valeur maximale 100")
    .required("Ce champ est obligatoire !"),

  isReprise: yup.boolean(),
});

export default EditGradesShema;
