import { Link, NavLink } from "react-router-dom";
import { MenuAvatar } from "./../Ui/MenuAvatar";
import { ImUsers } from "react-icons/im";
type MenuProps = {
  display?: boolean;
};
const LeftSideBar = ({ display = false }: MenuProps) => {
  return (
    <>
      <div
        className='left-side-menu'
        style={{
          display: `${display && "block"}`,
          // width: `${display && "100%"}`,
        }}
      >
        <div className='h-100' data-simplebar>
          <div id='sidebar-menu'>
            <ul id='side-mFenu'>
              <>
                <li>
                  <NavLink
                    to='/dashbord'
                    // className={({ isActive }) => (isActive ? "bg-primary" : "")}
                  >
                    <MenuAvatar img={"home"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                        4
                      </span> */}
                    <span className='bold'> Acceuil </span>
                  </NavLink>
                </li>
                <li>
                  <Link to='/dashbord/users'>
                    <MenuAvatar img={"users"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                        4
                      </span> */}
                    {/* <ImUsers color='red' size='50px' /> */}
                    <span className='bold'> Utilisateurs </span>
                  </Link>
                </li>

                <li>
                  <Link to='/dashbord/employees'>
                    <MenuAvatar img={"employees"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                        4
                      </span> */}
                    <span className='bold'> Employées </span>
                  </Link>
                </li>

                <li>
                  <Link to='/dashbord/teachers'>
                    <MenuAvatar img={"teachers"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                        4
                      </span> */}
                    <span className='bold'> Professeurs </span>
                  </Link>
                </li>
              </>

              <li>
                <Link to='/dashbord/students'>
                  <MenuAvatar img={"employees"} />
                  {/* <span className='badge bg-info rounded-pill float-end'>
                    4
                  </span> */}
                  <span className='bold'> Etudiants </span>
                </Link>
              </li>

              <>
                <li>
                  <Link to='/dashbord/faculties'>
                    <MenuAvatar img={"faculty"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                    4
                  </span> */}
                    <span className='bold'> Les Facultés </span>
                  </Link>
                </li>

                <li>
                  <Link to='/dashbord/courses'>
                    <MenuAvatar img={"course"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                    4
                  </span> */}
                    <span className='bold'> Les cours </span>
                  </Link>
                </li>

                <li>
                  <Link to='/dashbord/catalogs'>
                    <MenuAvatar img={"catalogue"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                    4
                  </span> */}
                    <span className='bold'> Catalogues </span>
                  </Link>
                </li>

                   <li>
                  <Link to='/dashbord/grades'>
                    <MenuAvatar img={"catalogue"} />
                    {/* <span className='badge bg-info rounded-pill float-end'>
                    4
                  </span> */}
                    <span className='bold'> Notes </span>
                  </Link>
                </li>
              </>
            </ul>
          </div>
          <div className='clearfix' />
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
