import React, { PropsWithChildren, useEffect, useState } from "react";
import { Divider, Modal } from "antd";
import { useAppDispatch } from "./../../redux/hooks/hooks";
import { isOpenModal } from "../../redux/MainSlice";

type ModalTemplateProps = {
  title: JSX.Element;
  isSuccess?: boolean;
  button: JSX.Element;
};

export const ModalTemplate = ({
  title,
  isSuccess,
  children,
  button,
}: PropsWithChildren<ModalTemplateProps>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // initialize modal option
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
    }
  }, [isSuccess, setIsModalOpen]);

  return (
    <>
      <span
        onClick={() => {
          setIsModalOpen(true);
          dispatch(isOpenModal(true));
        }}
      >
        {button}
      </span>
      <Modal
        title={
          <>
            {title} <Divider />
          </>
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          dispatch(isOpenModal(false));
        }}
        footer={[]}
        width={700}
        maskClosable={false}
      >
        {children}
      </Modal>
    </>
  );
};
