import { useQuery } from "@tanstack/react-query";
import { CourseKey } from "../../ultils/keys";
import { GetCourse } from "../../actions/CourseAction";
import { useEffect, useState } from "react";

const useShowCourse = (id: string) => {
  const {
    data: course,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useQuery([CourseKey, parseInt(id)], () => GetCourse(id));

  return { course, isLoading, isError, error, isSuccess };
};

export default useShowCourse;
