import { Oval } from "react-loader-spinner";
interface LoadingType {
  title?: string;
}
export const DataLoading = ({ title }: LoadingType) => {
  return (
    <>
      <center>
        <p style={{ padding: "10px", backgroundColor: "white" }}>
          <Oval
            height='30'
            width='30'
            color='#7d56c2'
            ariaLabel='audio-loading'
            wrapperClass='wrapper-class'
            wrapperStyle={{ display: "block" }}
            visible={true}
          />
          <h6> {title || "Chargment en cours..."} </h6>
        </p>
      </center>
    </>
  );
};
