import React, { PropsWithChildren } from "react";

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>{children}</div>
      </div>
    </div>
  );
};
