import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EditGradesShema from "../../shemaForms/grades/EditGradesShema";

export const EditGradesForm = ({
  onSubmit,
  loading,
  grades,
  students,
}: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditGradesShema),
    defaultValues: {
      note_edit: grades.notes,
      isReprise: grades.isreprise,
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className='mb-1'>
          Saisir la note pour {students.prenom} {students.nom}{" "}
        </label>

        <div className='input-group'>
          <input
            type='text'
            className='form-control'
            placeholder='Saisir la note'
            {...register("note_edit")}
            //   disabled={!isSuccess && !isLoading}
          />
        </div>

        <div className='form-check form-switch mt-1'>
          <input
            type='checkbox'
            className='form-check-input'
            id='customSwitch1'
            {...register("isReprise")}
          />
          <label className='form-check-label' htmlFor='customSwitch1'>
            Une note de reprise ?
          </label>
        </div>

        <button
          className={`input-group-text btn btn-primary waves-effect mt-2`}
          type='submit'
          disabled={loading}
        >
          <span
            className={`${loading && "spinner-border"} spinner-border-sm me-1`}
            role='status'
            aria-hidden='true'
          />
          <b> Modifier </b>
        </button>

        <div className='errors-field'>
          {errors.note_edit?.message?.toString()}
        </div>
      </form>
    </>
  );
};
