import { createSlice } from "@reduxjs/toolkit";
// Define a type for the slice state
interface GradesSlice {
  changeSession: string;
}

// Define the initial state using that type
const initialState: GradesSlice = {
  changeSession: "I",
};

export const GradesSlice = createSlice({
  name: "grade",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    changeSession: (state, action) => {
      state.changeSession = action.payload;
    },
  },
});

export const { changeSession } = GradesSlice.actions;
export default GradesSlice.reducer;
