import { Box, LinearProgress } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/UserAction";
import { Avatar } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { ResponsiveMenu } from "./ResponsiveMenu";
import { useMediaQuery } from "react-responsive";
import { Typography } from "antd";
const { Title } = Typography;

const TopBar = ({ user }: any) => {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const { isLoading, mutate } = useMutation(
    async () => {
      return await logout();
    },
    {
      onSuccess: (result) => {
        localStorage.removeItem("wpwuab");
        delete axios.defaults.headers.common["Authorization"];
        return navigate("/");
      },
    }
  );

  const onClick = async () => {
    mutate();
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color='secondary' />
        </Box>
      )}

      {/* Topbar Start */}
      {isTabletOrMobile && isPortrait && (
        <span style={{ zIndex: 1 }}>
          <ResponsiveMenu />
        </span>
      )}

      <div className='navbar-custom' style={{ zIndex: 1 }}>
        <div className='container-fluid'>
          <ul className='list-unstyled topnav-menu float-end mb-0'>
            <li className='d-none d-lg-block'>
              <form className='app-search'>
                <div className='app-search-box dropdown'>
                  <div className='input-group'>
                    <input
                      type='search'
                      className='form-control'
                      placeholder='Search...'
                      id='top-search'
                    />
                    <button className='btn input-group-text' type='submit'>
                      <i className='fa fa-search' />
                    </button>
                  </div>
                </div>
              </form>
            </li>

            <li className='dropdown notification-list topbar-dropdown'>
              <span
                className='nav-link dropdown-toggle nav-user me-0 waves-effect waves-light'
                data-bs-toggle='dropdown'
                role='button'
                aria-haspopup='false'
                aria-expanded='false'
              >
                <Avatar
                  icon={<PictureOutlined />}
                  src='/assets/images/avatar.png'
                />
                <span className='pro-user-name ms-1'>
                  {user.person?.prenom} {user.person?.nom}{" "}
                  <i className='fa fa-chevron-down' />
                </span>
              </span>
              <div className='dropdown-menu dropdown-menu-end profile-dropdown'>
                {/* item*/}
                <div className='dropdown-header noti-title'>
                  <h6 className='text-overflow m-0'>Bienvenue !</h6>
                </div>
                {/* item*/}
                <span className='dropdown-item notify-item'>
                  <i className='fa fa-user' />
                  <span>Mon compte</span>
                </span>
                <div className='dropdown-divider' />
                {/* item*/}
                <span
                  className='dropdown-item notify-item'
                  style={{ cursor: "pointer" }}
                  onClick={onClick}
                >
                  <i className='fa fa-signup' />
                  <span>Déconnexion</span>
                </span>
              </div>
            </li>
            {/* <li className='dropdown notification-list'>
              <a
                href='javascript:void(0);'
                className='nav-link right-bar-toggle waves-effect waves-light'
              >
                <i className='fe-settings noti-icon' />
              </a>
            </li> */}
          </ul>
          {/* LOGO */}

          <div
            className='logo-box'
            style={{
              backgroundColor: "white",

              //backgroundColor: `${isTabletOrMobile && isPortrait && "white"}`,
              // width: `${display && "100%"}`,
            }}
          >
            <span className='logo logo-light text-center'>
              {isTabletOrMobile && isPortrait ? (
                <span
                  className='logo-lg-text-light'
                  style={{ position: "absolute", left: "75px" }}
                >
                  <Title>
                    <b style={{ fontSize: "30px" }}>UJEPH</b>
                  </Title>
                </span>
              ) : (
                <>
                  {/* <Avatar
                    icon={<PictureOutlined />}
                    src='/assets/images/logo.jpeg'
                    size={50}
                    shape='square'
                  /> */}
                  <Title style={{ color: "##543aa2" }}>
                    <b>UJEPH</b>
                  </Title>
                </>
                // <span
                //   className='logo-lg-text-light'
                //   style={{ color: "#7d56c2" }}
                // >
                //   <span style={{ color: "red", fontWeight: "bold" }}>U</span>
                //   JEPH
                // </span>
              )}
            </span>
          </div>
        </div>
      </div>

      {/* end Topbar */}
    </>
  );
};

export default TopBar;
