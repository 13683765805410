import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

export const CourseListButtom = ({ courses }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const onChange = (e: any) => {
    if (e.target.value !== "") {
      navigate(
        `?sem=${parsed.sem}&level=${parsed.level}&year=${parsed.year}&grades=true&course=${e.target.value}`
      );
    }
  };

  return (
    <>
      <div className='dropdown float-end'>
        <select className='form-select form-select-sm' onChange={onChange}>
          <option value='' selected>
            Choisir une cours
          </option>

          {courses &&
            courses.map((courses: any) => (
              <option value={courses.courses.id}>
                {courses.courses.nom_cours}
              </option>
            ))}
        </select>
      </div>{" "}
    </>
  );
};
