import React, { PropsWithChildren } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type RefetchingProps = {
  loading: boolean;
  message?: string;
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 27,
      color: "#6340b0",
    }}
    spin
  />
);

export const IsRefetching = ({
  children,
  loading,
  message = "",
}: PropsWithChildren<RefetchingProps>) => {
  return (
    <Spin indicator={antIcon} tip={message} spinning={loading}>
      {children}
    </Spin>
  );
};
