import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { JsxElement } from "typescript";

type ButtonProps = {
  link?: string;
  className: string;
};

export const Button = ({
  link,
  className,
  children,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <div className=' text-sm-end'>
      {link ? (
        <Link to={link}>
          <button type='button' className={className}>
            {children}
          </button>
        </Link>
      ) : (
        <button type='button' className={className}>
          {children}
        </button>
      )}
    </div>
  );
};
