import { AiFillCaretRight } from "react-icons/ai";
interface PropsButton {
  message: string;
  loading: boolean;
}

const SubmitButtom = ({ message, loading }: PropsButton) => {
  return (
    <>
      <button
        type='submit'
        className='btn btn-primary'
        disabled={loading}
        style={{ fontWeight: 500 }}
      >
        <span
          className={`${loading && "spinner-border"} spinner-border-sm me-1`}
          role='status'
          aria-hidden='true'
        />
        {!loading ? message : "Chargement..."} <AiFillCaretRight size={18} />
      </button>
    </>
  );
};

export default SubmitButtom;
